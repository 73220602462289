// Apine
import Alpine from 'alpinejs'
import Tooltip from "@ryangjchandler/alpine-tooltip"

// Plugins
import Swiper from 'swiper/bundle'
import 'swiper/css/bundle'
import * as ScrollMagic from "@modules/scrollmagic";
import { TweenMax } from "@modules/gsap";
import { ScrollMagicPluginGsap } from "@modules/scrollmagic-plugins";
import { formatPrice } from './numbers'

const { MeiliSearch } = require('meilisearch')

// Scrollmagic specific
ScrollMagicPluginGsap(ScrollMagic, TweenMax);

// Extend webpack window classes
window.Swiper = Swiper
window.ScrollMagic = ScrollMagic
window.TweenMax = TweenMax
window.ScrollMagicPluginGsap = ScrollMagicPluginGsap
window.MeiliSearch = MeiliSearch
window.formatPrice = formatPrice

// Initialize alpine
Alpine.plugin(Tooltip)

// Add to window
window.Alpine = Alpine;
window.Alpine.start();